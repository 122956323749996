import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Helper from "../../Helper/helper";
import ContentLoader from "../../Static/contentLoader";
import AuthHeader from '../../Layouts/SubLayout/AuthHeader'
import api from "../../../Environment";
import Slider from "react-slick";

import io from "socket.io-client";

import { apiConstants } from "../../Constant/constants";

import './MovieSingle.css'

const socket = apiConstants.socketUrl ? io(apiConstants.socketUrl) : "";

let userId = localStorage.getItem("userId");

let accessToken = localStorage.getItem("accessToken");

class VideoComponent extends Helper {
  state = {
    loadingFirst: true,
    videoDetailsFirst: null,
    onPlayStarted: false,
    videoList: {},
    videoData: null,
    videoId: 0,
    socket: false,
    query: "",
    onSeekPlay: true,
    socketConnection: true,
    videoDuration: 0,
    socketConnected: false,
    suggestion2: null,
    loadingSuggestion2: true,
  };

  morelikeSlider = {
    dots: false,
    arrow: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  
  componentDidMount() {
    const videoId = this.props.match.params.id
    console.log('videoId',videoId)
    if(videoId){
      let inputData = {
        admin_video_id: videoId,
        skip: 0,
      }
      if(accessToken && accessToken != null)
        this.singleVideoFirst(inputData)
      else
        this.singleVideoFreeDetail(inputData);
      
      if(this.state.videoDetailsFirst){
        this.setState({ loadingFirst: false });
      }else{
        this.setState({ loadingFirst: true });
      }
      this.suggestion2(inputData);
    }else {
      window.location = "/home";
    }
    // console.log('singleVideoFreeDetail',this.state.videoDetailsFirst)
    // if (this.props.location.state) {
    //   this.setState({ loadingFirst: false });

    //   // so user cant download film
    //   // document.addEventListener('contextmenu', event => window.BLOCKRIGHTCLICK? event.preventDefault(): "");
    //   // window.BLOCKRIGHTCLICK = true;
    // } 
  }

  timer = async () => {
    if (this.state.onPlayStarted) {
      await this.socketConnectionfun(userId, accessToken);
    }
  };

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
    window.BLOCKRIGHTCLICK = false;
  }

  onCompleteVideo = () => {
    this.addHistory(this.props.location.state.videoDetailsFirst.admin_video_id);
    this.setState({ onPlayStarted: false, socketConnection: false });
    if (this.state.socketConnected) {
      socket.emit("disconnect");
    }
  };

  onVideoPlay = async () => {
    let intervalId = setInterval(this.timer, 3000);

    this.setState({ intervalId: intervalId });

    this.setState({ onPlayStarted: true, socketConnection: true });

    let inputData = {
      admin_video_id: this.state.videoDetailsFirst
        .admin_video_id,
    };
    await this.onlySingleVideoFirst(inputData);

    this.redirectStatus(this.state.videoDetailsFirst);

    const seekTime = this.state.videoDetailsFirst.seek_time_in_seconds
      ? this.state.videoDetailsFirst.seek_time_in_seconds
      : 0;

    console.log(seekTime);

    if (this.state.onSeekPlay) {
      this.player.seekTo(parseFloat(seekTime));
    }

    this.setState({ onSeekPlay: false });
  };

  addHistory = (admin_video_id) => {
    if(userId && accessToken && accessToken !== '' && userId !== ''){
      api
        .postMethod("addHistory", { id:userId,admin_video_id: admin_video_id,sub_profile_id:userId,type:"video" })
        .then((response) => {
          if (response.data.success === true) {
          } else {
          }
        })
        .catch(function(error) {});
    }
  };

  socketConnectionfun = (userId, accessToken) => {
    if (apiConstants.socketUrl) {
      let videoId = this.state.videoDetailsFirst.admin_video_id;

      socket.on("connect", function() {
        let query = `user_id=` + userId + `&video_id=` + videoId;
      });

      socket.on("connected", function() {
        console.log("Connected");
        this.setState({ socketConnected: true });
      });

      socket.on("disconnect", function() {
        console.log("disconnect");
        this.setState({ socketConnected: false });
      });

      console.log(this.state.videoDuration);

      let videoData = [
        {
          sub_profile_id: "",
          admin_video_id: videoId,
          id: userId,
          token: accessToken,
          duration: this.state.videoDuration,
        },
      ];

      socket.emit("save_continue_watching_video", videoData[0]);
    }
  };

  onPauseVideo = async () => {
    console.log("onPause");
    if (this.state.socketConnected) {
      socket.emit("disconnect");
    }
    clearInterval(this.state.intervalId);
  };

  onVideoTimeUpdate = (duration) => {
    let video_duration = duration.target.currentTime;

    let sec = parseInt(video_duration % 60);
    let min = parseInt((video_duration / 60) % 60);
    let hours = parseInt(video_duration / 3600);

    if (hours > 1) {
      this.setState({ videoDuration: hours + ":" + min + ":" + sec });
    } else {
      this.setState({ videoDuration: min + ":" + sec });
    }
  };

  ref = (player) => {
    this.player = player;
  };

  renderedVideoPlayerView = (mainVideo,videoType,videoTitle,subTitle) =>{
    return  <div>
                <div className="single-video">
                  <ReactPlayer
                    ref={this.ref}
                    // url={[
                    //   {
                    //     src:
                    //       "http://adminview.streamhash.com:8080/426x240SV-201…8-59-443b8c7d4d68e41bb9a618a0de9a5f4003710241.mp4",
                    //     type: "video/webm"
                    //   },

                    //   {
                    //     src:
                    //       "http://adminview.streamhash.com:8080/640x360SV-2019-09-23-05-18-59-443b8c7d4d68e41bb9a618a0de9a5f4003710241.mp4",
                    //     type: "video/ogg"
                    //   }
                    // ]}
                    url={mainVideo}
                    controls={true}
                    width="100%"
                    height="100vh"
                    playing={true}
                    onStart={this.onLoad}
                    onPause={this.onPauseVideo}
                    onPlay={
                      this.state.videoFrom == "trailer"
                        ? ""
                        : this.onVideoPlay
                    }
                    onEnded={this.onCompleteVideo}
                    onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    light={this.state.videoDetailsFirst.default_image}
                    config={{
                      file: {
                        tracks: [
                          {
                            kind: "subtitles",
                            src: subTitle,
                            srcLang: "en",
                            default: true,
                          },
                        ],
                        attributes: {
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  />
                  <div className="back-arrowsec">
                    <Link to="/home">
                      <img
                        src={window.location.origin + "/assets/img/left-arrow.png"}
                        alt="arrow"
                      />
                      {videoType == 2 ? (
                        ""
                      ) : (
                        <span className="txt-overflow capitalize ml-3">
                          {videoTitle}
                        </span>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
  }

  DATE_OPTIONS = {
    year: "numeric"
  };

  renderedMovieDetailView = (videoDetail) =>{
    return <div className="moviesSingle">
              <AuthHeader/>
              <div className="movie-container ">
                  <img className="movie-img-primary" src={videoDetail.default_image} alt="" />
                  <div className="movie-primary-detail-container">
                      <div className="movie-primary-detail-items">
                        <img className="movie-logo" src={videoDetail.logo_image} alt="" />
                      <h1>{videoDetail.title}</h1>
                      <p className="movie-meta"> <span>
                        {new Date(videoDetail.publish_time).toLocaleDateString(
                          "en-US",
                          this.DATE_OPTIONS
                        )} |</span> <span>U/A {videoDetail.age}+ |</span>  <span>{videoDetail.duration} |</span>  <span>{videoDetail.categoryDetails.name}</span>  </p>
                      <p className="movie-description">{videoDetail.description}</p>
                      <p className="starring-meta"> <span style={{color:"#a3a3a3"}}>Starring : </span> 
                        {videoDetail.cast_crews.length > 0 ? videoDetail.cast_crews.map(cast => cast.name).join(',') : '' }
                      </p>
                      </div>
                    

                  </div>
                
                  <div className="extra-container">
                <div>
                <img src="https://admin-watch.antidotefilms.com.au/uploads/s…6-31-62e6f727c8600b037b5b7de33c43542262375b83.png" className="website-logo" alt="" />
                <h4>Watch all you want.</h4>
                </div>
              <div>
                <a href="/login" className="btn btn-danger">Join Now</a>
              </div>
              </div>
              </div>
            <div className="movie-single-extra">
              <div style={{padding:"10px 5px"}} className="row">
                    <div className="pr-4per pl-4per">
                      <h1 className="banner_video_title">{("More like this")}</h1>
                    </div>
                      <Slider {...this.morelikeSlider} className="more-like-slider slider">
                        {this.state.suggestion2 && this.state.suggestion2.map((suggest) => (
                          <div key={suggest.id}>
                            <div className="relative">
                              <img
                                className="trailers-img placeholder"
                                alt="episode-img"
                                src={suggest.default_image}
                                data-src="assets/img/thumb1.jpg"
                                srcSet={
                                  suggest.default_image +
                                  " 1x," +
                                  suggest.default_image +
                                  " 1.5x," +
                                  suggest.default_image +
                                  " 2x"
                                }
                                data-srcset="assets/img/thumb1.jpg 1x,
                                                                      assets/img/thumb1.jpg 1.5x,
                                                                      assets/img/thumb1.jpg 2x"
                              />
                              <div className="trailers-img-overlay">
                                <a
                                  href={`/video/${suggest.id}`}
                                >
                                  <div className="thumbslider-outline">
                                    <i className="fas fa-play" />
                                  </div>
                                </a>
                                {/* <div className="add-to-wishlist">
                                                        <Link to="#">
                                                            <i className="fas fa-plus-circle" />
                                                        </Link>
                                                    </div> */}
                              </div>
                            </div>
                            <div className="episode-content">
                            
                              <h4 className="episode-content-head">
                                <span>{suggest.title}</span>
                                &nbsp;
                              <br/>  <span className="grey-box pt-0 pb-0">
                                  {suggest.age} <i className="fas fa-plus small" /> /{" "}
                                  {suggest.category_id} <small>views</small>
                                </span>
                                &nbsp;
                                <span>{suggest.duration}</span>&nbsp;
                              </h4>
                              <h4 className="episode-content-desc">
                                {suggest.description}
                              </h4>
                            </div>
                          </div>
                        ))}
                      </Slider>
              </div>
            </div>
          </div>
  }

  render() {
    const pageType = "videoPage";
    if (this.state.onPlayStarted) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst,
        pageType
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    const { loadingFirst } = this.state;
    let mainVideo, videoTitle, videoType, subTitle;

    if (loadingFirst) {
      return <ContentLoader />;
    } else {
      // Check the whether we need to play the trailer or main video
      const videoDetail = this.state.videoDetailsFirst;
      if (this.state.videoFrom != undefined) {
        subTitle = this.state.videoDetailsFirst
          .video_subtitle_vtt;

        if (this.state.videoFrom == "trailer") {
          mainVideo = this.state.videoDetailsFirst.resolutions
            .original;
          subTitle = this.state.videoDetailsFirst
            .trailer_subtitle;
        } else {
          mainVideo = this.state.videoDetailsFirst.resolutions
            .original;
        }

        videoTitle = this.state.videoDetailsFirst.name;

        videoType = this.state.videoDetailsFirst.video_type;
      } else {
        mainVideo = this.state.videoDetailsFirst.main_video;

        subTitle = this.state.videoDetailsFirst
          .video_subtitle_vtt;

        videoTitle = this.state.videoDetailsFirst.title;

        videoType = this.state.videoDetailsFirst.video_type;
      }

      return (
        accessToken && accessToken != null ? this.renderedVideoPlayerView(mainVideo,videoType,videoTitle,subTitle)  : this.renderedMovieDetailView(videoDetail)
      );
    }
  }
}

export default VideoComponent;
