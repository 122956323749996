import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Helper from "../../Helper/helper";
import ContentLoader from "../../Static/contentLoader";
import AuthHeader from '../../Layouts/SubLayout/AuthHeader'
import api from "../../../Environment";

import io from "socket.io-client";

import { apiConstants } from "../../Constant/constants";

import './MovieSingle.css'

const socket = apiConstants.socketUrl ? io(apiConstants.socketUrl) : "";

let userId = localStorage.getItem("userId");

let accessToken = localStorage.getItem("accessToken");

class PlayTrailerVideoPage extends Helper {
  state = {
    loadingFirst: true,
    videoDetailsFirst: null,
    onPlayStarted: false,
    videoList: {},
    videoData: null,
    videoId: 0,
    socket: false,
    query: "",
    onSeekPlay: true,
    socketConnection: true,
    videoDuration: 0,
    socketConnected: false,
  };
  
  componentDidMount() {
    const videoId = this.props.match.params.id
    console.log('trailer video',videoId)
    if(videoId){
      let inputData = {
        admin_video_id: videoId,
        skip: 0,
      }
      if(accessToken && accessToken != null)
        this.singleVideoFirst(inputData)
      else
        this.singleVideoFreeDetail(inputData);
      
      if(this.state.videoDetailsFirst){
        this.setState({ loadingFirst: false });
      }else{
        this.setState({ loadingFirst: true });
      }
      
    }else {
      window.location = "/home";
    }
    // console.log('singleVideoFreeDetail',this.state.videoDetailsFirst)
    // if (this.props.location.state) {
    //   this.setState({ loadingFirst: false });

    //   // so user cant download film
    //   // document.addEventListener('contextmenu', event => window.BLOCKRIGHTCLICK? event.preventDefault(): "");
    //   // window.BLOCKRIGHTCLICK = true;
    // } 
  }

  timer = async () => {
    if (this.state.onPlayStarted) {
      await this.socketConnectionfun(userId, accessToken);
    }
  };

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
    window.BLOCKRIGHTCLICK = false;
  }

  onCompleteVideo = () => {
    this.setState({ onPlayStarted: false, socketConnection: false });
    if (this.state.socketConnected) {
      socket.emit("disconnect");
    }
  };

  addHistory = (admin_video_id) => {
    if(userId && accessToken && accessToken !== '' && userId !== ''){
      api
      .postMethod("addHistory", { id:userId,admin_video_id: admin_video_id,sub_profile_id:userId,type:"trailer" })
      .then((response) => {
        if (response.data.success === true) {
        } else {
        }
      })
      .catch(function(error) {});
    }
  };

  onVideoPlay = async () => {
    let intervalId = setInterval(this.timer, 3000);

    this.setState({ intervalId: intervalId });

    this.setState({ onPlayStarted: true, socketConnection: true });

    let inputData = {
      admin_video_id: this.state.videoDetailsFirst.admin_video_id,
    };
    await this.onlySingleVideoFirst(inputData);

    this.redirectStatus(this.state.videoDetailsFirst);

    const seekTime = this.state.videoDetailsFirst.seek_time_in_seconds
      ? this.state.videoDetailsFirst.seek_time_in_seconds
      : 0;

    console.log(seekTime);

    if (this.state.onSeekPlay) {
      this.player.seekTo(parseFloat(seekTime));
    }

    this.setState({ onSeekPlay: false });

    this.addHistory(this.state.videoDetailsFirst.admin_video_id);

  };
  

  socketConnectionfun = (userId, accessToken) => {
    if (apiConstants.socketUrl) {
      let videoId = this.state.videoDetailsFirst.admin_video_id;

      socket.on("connect", function() {
        let query = `user_id=` + userId + `&video_id=` + videoId;
      });

      socket.on("connected", function() {
        console.log("Connected");
        this.setState({ socketConnected: true });
      });

      socket.on("disconnect", function() {
        console.log("disconnect");
        this.setState({ socketConnected: false });
      });

      console.log(this.state.videoDuration);

      let videoData = [
        {
          sub_profile_id: "",
          admin_video_id: videoId,
          id: userId,
          token: accessToken,
          duration: this.state.videoDuration,
        },
      ];

      socket.emit("save_continue_watching_video", videoData[0]);
    }
  };

  onPauseVideo = async () => {
    console.log("onPause");
    if (this.state.socketConnected) {
      socket.emit("disconnect");
    }
    clearInterval(this.state.intervalId);
  };

  onVideoTimeUpdate = (duration) => {
    let video_duration = duration.target.currentTime;

    let sec = parseInt(video_duration % 60);
    let min = parseInt((video_duration / 60) % 60);
    let hours = parseInt(video_duration / 3600);

    if (hours > 1) {
      this.setState({ videoDuration: hours + ":" + min + ":" + sec });
    } else {
      this.setState({ videoDuration: min + ":" + sec });
    }
  };

  ref = (player) => {
    this.player = player;
  };

  renderedVideoPlayerView = (mainVideo,videoType,videoTitle,subTitle) =>{
    return  <div>
                <div className="single-video">
                  <ReactPlayer
                    ref={this.ref}
                    // url={[
                    //   {
                    //     src:
                    //       "http://adminview.streamhash.com:8080/426x240SV-201…8-59-443b8c7d4d68e41bb9a618a0de9a5f4003710241.mp4",
                    //     type: "video/webm"
                    //   },

                    //   {
                    //     src:
                    //       "http://adminview.streamhash.com:8080/640x360SV-2019-09-23-05-18-59-443b8c7d4d68e41bb9a618a0de9a5f4003710241.mp4",
                    //     type: "video/ogg"
                    //   }
                    // ]}
                    url={mainVideo}
                    controls={true}
                    width="100%"
                    height="100vh"
                    playing={true}
                    onStart={this.onLoad}
                    onPause={this.onPauseVideo}
                    onPlay={this.onVideoPlay}
                    onEnded={this.onCompleteVideo}
                    onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    light={this.state.videoDetailsFirst.default_image}
                    config={{
                      file: {
                        tracks: [
                          {
                            kind: "subtitles",
                            src: subTitle,
                            srcLang: "en",
                            default: true,
                          },
                        ],
                        attributes: {
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  />
                  <div className="back-arrowsec">
                    <Link to="/home">
                      <img
                        src={window.location.origin + "/assets/img/left-arrow.png"}
                        alt="arrow"
                      />
                      {videoType == 2 ? (
                        ""
                      ) : (
                        <span className="txt-overflow capitalize ml-3">
                          {videoTitle}
                        </span>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
  }

  render() {
    
    const { loadingFirst } = this.state;
    let mainVideo, videoTitle, videoType, subTitle;

    if (loadingFirst) {
      return <ContentLoader />;
    } else {
      // Check the whether we need to play the trailer or main video
        mainVideo = this.state.videoDetailsFirst.trailer_video;

        subTitle = this.state.videoDetailsFirst
          .video_subtitle_vtt;

        videoTitle = this.state.videoDetailsFirst.title;

        videoType = this.state.videoDetailsFirst.video_type;

      return (
        this.renderedVideoPlayerView(mainVideo,videoType,videoTitle,subTitle)
      );
    }
  }
}

export default PlayTrailerVideoPage;
